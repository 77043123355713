import BlanceTopUpHistory from "../../../components/admin/blanceTopUpHistory/BlanceTopUpHistory"


function BlanceTopUpHistoryPage() {
    return (
        <>
            <BlanceTopUpHistory />
        </>
    )
}
export default BlanceTopUpHistoryPage