import PanService from "../../../components/admin/panService/PanServices"


function PanServicesPage() {
    return (
        <>
            <PanService />
        </>
    )
}
export default PanServicesPage