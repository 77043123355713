import AddTicket from "../../../../components/admin/disput/AddTicket/AddTicket"


function AddTicketPage() {
    return (
        <>
            <AddTicket />
        </>
    )
}
export default AddTicketPage