import React, { useState } from 'react';

const data = [
  {
    transactionDate: '01-08-2024 10:30:00 AM',
    studentName: 'John Doe',
    studentID: '123456',
    course: 'Computer Science',
    amount: '$500',
    paymentMethod: 'Credit Card',
    status: 'Completed',
  },
  {
    transactionDate: '30-07-2024 02:15:00 PM',
    studentName: 'Jane Smith',
    studentID: '789012',
    course: 'Mathematics',
    amount: '$400',
    paymentMethod: 'PayPal',
    status: 'Pending',
  },
  {
    transactionDate: '29-07-2024 11:45:00 AM',
    studentName: 'Michael Brown',
    studentID: '345678',
    course: 'Physics',
    amount: '$450',
    paymentMethod: 'Debit Card',
    status: 'Completed',
  },
  {
    transactionDate: '01-08-2024 10:30:00 AM',
    studentName: 'John Doe',
    studentID: '123456',
    course: 'Computer Science',
    amount: '$500',
    paymentMethod: 'Credit Card',
    status: 'Completed',
  },
  {
    transactionDate: '30-07-2024 02:15:00 PM',
    studentName: 'Jane Smith',
    studentID: '789012',
    course: 'Mathematics',
    amount: '$400',
    paymentMethod: 'PayPal',
    status: 'Pending',
  },
  {
    transactionDate: '29-07-2024 11:45:00 AM',
    studentName: 'Michael Brown',
    studentID: '345678',
    course: 'Physics',
    amount: '$450',
    paymentMethod: 'Debit Card',
    status: 'Completed',
  },
  {
    transactionDate: '01-08-2024 10:30:00 AM',
    studentName: 'John Doe',
    studentID: '123456',
    course: 'Computer Science',
    amount: '$500',
    paymentMethod: 'Credit Card',
    status: 'Completed',
  },
  {
    transactionDate: '30-07-2024 02:15:00 PM',
    studentName: 'Jane Smith',
    studentID: '789012',
    course: 'Mathematics',
    amount: '$400',
    paymentMethod: 'PayPal',
    status: 'Pending',
  },
  {
    transactionDate: '29-07-2024 11:45:00 AM',
    studentName: 'Michael Brown',
    studentID: '345678',
    course: 'Physics',
    amount: '$450',
    paymentMethod: 'Debit Card',
    status: 'Completed',
  },
  {
    transactionDate: '01-08-2024 10:30:00 AM',
    studentName: 'John Doe',
    studentID: '123456',
    course: 'Computer Science',
    amount: '$500',
    paymentMethod: 'Credit Card',
    status: 'Completed',
  },
  {
    transactionDate: '30-07-2024 02:15:00 PM',
    studentName: 'Jane Smith',
    studentID: '789012',
    course: 'Mathematics',
    amount: '$400',
    paymentMethod: 'PayPal',
    status: 'Pending',
  },
  {
    transactionDate: '29-07-2024 11:45:00 AM',
    studentName: 'Michael Brown',
    studentID: '345678',
    course: 'Physics',
    amount: '$450',
    paymentMethod: 'Debit Card',
    status: 'Completed',
  },
];

const MyTrasactionsRepo = () => {
    const [searchInputs, setSearchInputs] = useState({
      transactionDate: '',
      studentName: '',
      studentID: '',
      course: '',
      amount: '',
      paymentMethod: '',
      status: '',
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5); // Number of items per page
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setSearchInputs({
        ...searchInputs,
        [name]: value,
      });
      setCurrentPage(1); // Reset to first page on search
    };
  
    const filteredData = data.filter((item) =>
      Object.keys(searchInputs).every((key) =>
        item[key].toLowerCase().includes(searchInputs[key].toLowerCase())
      )
    );
  
    // Calculate the displayed items based on pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  
    const handlePrevPage = () => {
      setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };
  
    const handleNextPage = () => {
      setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };
  
    return (
      <div>
        <table style={{ width: '1300px', margin: '10px auto' }}>
          <thead>
            <tr>
              {Object.keys(searchInputs).map((key) => (
                <th key={key}>
                  <input
                    type="text"
                    className='form-control'
                    name={key}
                    placeholder="Search"
                    value={searchInputs[key]}
                    onChange={handleInputChange}
                  />
                </th>
              ))}
            </tr>
            <tr>
              <th>Transaction Date</th>
              <th>Student Name</th>
              <th>Student ID</th>
              <th>Course</th>
              <th>Amount</th>
              <th>Payment Method</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item, index) => (
              <tr key={index}>
                <td>{item.transactionDate}</td>
                <td>{item.studentName}</td>
                <td>{item.studentID}</td>
                <td>{item.course}</td>
                <td>{item.amount}</td>
                <td>{item.paymentMethod}</td>
                <td>{item.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div style={{ textAlign: 'center', margin: '20px 0' }}>
          <button className='btn btn-primary' onClick={handlePrevPage} disabled={currentPage === 1}>
            Previous
          </button>
          <span style={{ margin: '0 10px' }}>
            Page {currentPage} of {totalPages}
          </span>
          <button className='btn btn-primary' onClick={handleNextPage} disabled={currentPage === totalPages}>
            Next
          </button>
        </div>
      </div>
    );
  };
  
export default MyTrasactionsRepo;
