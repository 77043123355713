import ActivityLog from "../../../components/admin/activityLg/ActivityLog"


function ActivityLogPage() {
    return (
        <>
            <ActivityLog />
        </>
    )
}
export default ActivityLogPage