import SearchRechargeHistory from "../../../components/admin/searchRechargeHistory/SearchRechargeHistory"


function SearchRechargeHistoryPage() {
    return (
        <>
            <SearchRechargeHistory />
        </>
    )
}
export default SearchRechargeHistoryPage