import TranactionReports from "../../../components/admin/tranactionReports/TranactionReports"


function TransactionReportPage() {
    return (
        <>
            <TranactionReports />
        </>
    )
}
export default TransactionReportPage