import PaymentRequestView from "../../../../components/admin/payment/PaymentRequestView/PaymentRequestView"


function PaymentRequestViewPage() {
    return (
        <>
            <PaymentRequestView />
        </>
    )
}
export default PaymentRequestViewPage