import { useEffect, useState } from "react";
import {
  cloudImage,
  uploadDocument,
  userValidate,
} from "../../api/login/Login";
import { ToastContainer, toast } from "react-toastify";
import { FaEye } from "react-icons/fa";
import { ModalImgShow } from "./ModalImgShow";
import Modal from "react-bootstrap/Modal";
import { toastErrorMessage, toastSuccessMessage } from "./ToastShare";

export const UploadDoc = ({ setState, docs, setModalShow, backBtnFun, setBackBtn }) => {
  const [position, setPosition] = useState({ latitude: null, longitude: null });
  console.log(position);

  const [isGstAvailable, setIsGstAvailable] = useState(false);
  const [fileList, setFileList] = useState([]);
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const [initialValue, setInitialValue] = useState({
    neet_mark_sheet: "",
    neet_admit_card: "",
    tenth_class_marksheet: "",
    twelfth_class_marksheet: "",
    transfer_certificate: "",
    migration_certificate: "",
    character_certificate: "",
    domicile_certificate: "",
    income_certificate: "",
    gap_year_affidavit: "",
    original_id: "",
    bonds: "",
    passport_size_photos: "",
    post_card_size_photo: "",
    user_id: "",
    latitude: "",
    longitude: "",
  });

  const [imageValidity, setImageValidity] = useState({
    neet_mark_sheet: true,
    neet_admit_card: true,
    tenth_class_marksheet: true,
    twelfth_class_marksheet: true,
    transfer_certificate: true,
    migration_certificate: true,
    character_certificate: true,
    domicile_certificate: true,
    income_certificate: true,
    gap_year_affidavit: true,
    original_id: true,
    bonds: true,
    passport_size_photos: true,
    post_card_size_photo: true,
  });

  const [btnDisable, setBtnDisable] = useState(true);

  useEffect(() => {
    if (docs?.neet_admit_card) {
      setInitialValue({ ...docs });
    }
  }, [docs]);

  const gstIdVerified = async () => {
    try {
      const res = await userValidate();
      if (res.data.isGstAvailable) {
        setIsGstAvailable(true);
      }
    } catch (error) {
      console.error("GST Validation Error:", error);
    }
  };

  useEffect(() => {
    gstIdVerified();
    if (!btnDisable) {
      setBackBtn(false);
    }
  }, [btnDisable]);

  const onChangeHandleImage = async (e) => {
    const image = new FormData();
    image.append("image", e.target.files[0]);

    const allowedTypes = [
      "image/jpeg",
      "image/png",
      "image/webp",
      "application/pdf",
    ];

    if (e.target.files[0] && !allowedTypes.includes(e.target.files[0].type)) {
      toastErrorMessage("Invalid file type. Please select a valid file.");
      return;
    }

    const maxSize = 500 * 1024; // 500 KB
    const minSize = 225 * 1024; // 225 KB
    if (e.target.files[0].size > maxSize) {
      toastErrorMessage("Maximum file size exceeded (500 KB). Please select a smaller file.");
      return;
    }
    if (e.target.files[0].size < minSize) {
      toastErrorMessage("Minimum file size not met (225 KB). Please select a larger file.");
      return;
    }

    try {
      const res = await cloudImage(image);
      const clone = { ...initialValue, [e.target.name]: res.data?.data.url };

      if (
        clone.neet_mark_sheet?.length &&
        clone.neet_admit_card?.length &&
        clone.tenth_class_marksheet?.length &&
        clone.twelfth_class_marksheet?.length
      ) {
        setBtnDisable(false);
      }

      setTimeout(() => {
        setInitialValue(clone);
      }, 1000);

      image.delete("image");
    } catch (error) {
      console.error("Image Upload Error:", error);
      const clone = { ...initialValue, [e.target.name]: "" };
      setInitialValue(clone);
      console.log("clone1", clone);
    }
  };

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          setPosition({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        function (error) {
          console.error("Geolocation Error:", error);
        }
      );
    } else {
      alert("Geolocation is not available in your browser.");
    }
  }, []);

  const submitData = async () => {
    try {
      const res = await uploadDocument({
        ...initialValue,
        latitude: position.latitude,
        longitude: position.longitude,
        user_id: window.localStorage.getItem("userIdToken"),
      });
      if (res?.data?.statusCode === "200") {
        toastSuccessMessage("Document Upload Successful");
        setTimeout(() => {
          setState(5);
          window.localStorage.setItem("steps", 5);
          setModalShow(true);
        }, 1000);
      }
    } catch (error) {
      console.error("Document Upload Error:", error);
    }
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [cantain, setCantain] = useState({});

  const clickImgShow = (url, str) => {
    handleShow();
    setCantain({ url, str });
  };

  const backButton = () => {
    setState(3);
    window.localStorage.setItem("steps", 3);
  };

  return (
    <div className="container" style={{width:"1200px",marginLeft:"-260px"}}>
      <h6 style={{ margin: "15px 0", textAlign: "center" }}>
        {" "} Upload Required Documents
      </h6>
      <p style={{ margin: "15px 0", textAlign: "center" }}>
        Upload all required documents. Ensure the copies are clear and legible.
      </p>

      <form className="row">
        {/* NEET Mark Sheet */}
        <div className="form-group col-4">
          <label htmlFor="neetMarkSheet">Upload NEET Mark Sheet:</label>
          <input
            type="file"
            className="form-control"
            id="neetMarkSheet"
            name="neet_mark_sheet"
            onChange={onChangeHandleImage}
          />
          <p>File Supported: jpg, jpeg, webp, png, pdf</p>
          {initialValue.neet_mark_sheet && (
            <div
              className="perentEye d-flex justify-content-between align-items-center"
              onClick={() => {
                clickImgShow(initialValue.neet_mark_sheet, "NEET Mark Sheet");
              }}
            >
              <img
                src={`https://api.paypandabnk.com/api/cloudinary/${initialValue.neet_mark_sheet}`}
                alt="NEET Mark Sheet"
                className="img-fluid mt-2"
              />
              <div className="eyeV">
                <FaEye />
              </div>
            </div>
          )}
        </div>

        {/* NEET Admit Card */}
        <div className="form-group col-4">
          <label htmlFor="neetAdmitCard">Upload NEET Admit Card:</label>
          <input
            type="file"
            className="form-control"
            id="neetAdmitCard"
            name="neet_admit_card"
            onChange={onChangeHandleImage}
          />
          <p>File Supported: jpg, jpeg, webp, png, pdf</p>
          {initialValue.neet_admit_card && (
            <div
              className="perentEye d-flex justify-content-between align-items-center"
              onClick={() => {
                clickImgShow(initialValue.neet_admit_card, "NEET Admit Card");
              }}
            >
              <img
                src={`https://api.paypandabnk.com/api/cloudinary/${initialValue.neet_admit_card}`}
                alt="NEET Admit Card"
                className="img-fluid mt-2"
              />
              <div className="eyeV">
                <FaEye />
              </div>
            </div>
          )}
        </div>

        {/* 10th Class Marksheet + Certificate */}
        <div className="form-group col-4">
          <label htmlFor="tenthClassMarksheet">Upload 10th Class Marksheet + Certificate:</label>
          <input
            type="file"
            className="form-control"
            id="tenthClassMarksheet"
            name="tenth_class_marksheet"
            onChange={onChangeHandleImage}
          />
          <p>File Supported: jpg, jpeg, webp, png, pdf</p>
          {initialValue.tenth_class_marksheet && (
            <div
              className="perentEye d-flex justify-content-between align-items-center"
              onClick={() => {
                clickImgShow(initialValue.tenth_class_marksheet, "10th Class Marksheet + Certificate");
              }}
            >
              <img
                src={`https://api.paypandabnk.com/api/cloudinary/${initialValue.tenth_class_marksheet}`}
                alt="10th Class Marksheet + Certificate"
                className="img-fluid mt-2"
              />
              <div className="eyeV">
                <FaEye />
              </div>
            </div>
          )}
        </div>

        {/* 12th Class Marksheet + Certificate */}
        <div className="form-group col-4">
          <label htmlFor="twelfthClassMarksheet">Upload 12th Class Marksheet + Certificate:</label>
          <input
            type="file"
            className="form-control"
            id="twelfthClassMarksheet"
            name="twelfth_class_marksheet"
            onChange={onChangeHandleImage}
          />
          <p>File Supported: jpg, jpeg, webp, png, pdf</p>
          {initialValue.twelfth_class_marksheet && (
            <div
              className="perentEye d-flex justify-content-between align-items-center"
              onClick={() => {
                clickImgShow(initialValue.twelfth_class_marksheet, "12th Class Marksheet + Certificate");
              }}
            >
              <img
                src={`https://api.paypandabnk.com/api/cloudinary/${initialValue.twelfth_class_marksheet}`}
                alt="12th Class Marksheet + Certificate"
                className="img-fluid mt-2"
              />
              <div className="eyeV">
                <FaEye />
              </div>
            </div>
          )}
        </div>

        {/* Transfer Certificate */}
        <div className="form-group col-4">
          <label htmlFor="transferCertificate">Upload Transfer Certificate:</label>
          <input
            type="file"
            className="form-control"
            id="transferCertificate"
            name="transfer_certificate"
            onChange={onChangeHandleImage}
          />
          <p>File Supported: jpg, jpeg, webp, png, pdf</p>
          {initialValue.transfer_certificate && (
            <div
              className="perentEye d-flex justify-content-between align-items-center"
              onClick={() => {
                clickImgShow(initialValue.transfer_certificate, "Transfer Certificate");
              }}
            >
              <img
                src={`https://api.paypandabnk.com/api/cloudinary/${initialValue.transfer_certificate}`}
                alt="Transfer Certificate"
                className="img-fluid mt-2"
              />
              <div className="eyeV">
                <FaEye />
              </div>
            </div>
          )}
        </div>

        {/* Migration Certificate */}
        <div className="form-group col-4">
          <label htmlFor="migrationCertificate">Upload Migration Certificate:</label>
          <input
            type="file"
            className="form-control"
            id="migrationCertificate"
            name="migration_certificate"
            onChange={onChangeHandleImage}
          />
          <p>File Supported: jpg, jpeg, webp, png, pdf</p>
          {initialValue.migration_certificate && (
            <div
              className="perentEye d-flex justify-content-between align-items-center"
              onClick={() => {
                clickImgShow(initialValue.migration_certificate, "Migration Certificate");
              }}
            >
              <img
                src={`https://api.paypandabnk.com/api/cloudinary/${initialValue.migration_certificate}`}
                alt="Migration Certificate"
                className="img-fluid mt-2"
              />
              <div className="eyeV">
                <FaEye />
              </div>
            </div>
          )}
        </div>

        {/* Character Certificate */}
        <div className="form-group col-4">
          <label htmlFor="characterCertificate">Upload Character Certificate:</label>
          <input
            type="file"
            className="form-control"
            id="characterCertificate"
            name="character_certificate"
            onChange={onChangeHandleImage}
          />
          <p>File Supported: jpg, jpeg, webp, png, pdf</p>
          {initialValue.character_certificate && (
            <div
              className="perentEye d-flex justify-content-between align-items-center"
              onClick={() => {
                clickImgShow(initialValue.character_certificate, "Character Certificate");
              }}
            >
              <img
                src={`https://api.paypandabnk.com/api/cloudinary/${initialValue.character_certificate}`}
                alt="Character Certificate"
                className="img-fluid mt-2"
              />
              <div className="eyeV">
                <FaEye />
              </div>
            </div>
          )}
        </div>

        {/* Domicile Certificate */}
        <div className="form-group col-4">
          <label htmlFor="domicileCertificate">Upload Domicile Certificate:</label>
          <input
            type="file"
            className="form-control"
            id="domicileCertificate"
            name="domicile_certificate"
            onChange={onChangeHandleImage}
          />
          <p>File Supported: jpg, jpeg, webp, png, pdf</p>
          {initialValue.domicile_certificate && (
            <div
              className="perentEye d-flex justify-content-between align-items-center"
              onClick={() => {
                clickImgShow(initialValue.domicile_certificate, "Domicile Certificate");
              }}
            >
              <img
                src={`https://api.paypandabnk.com/api/cloudinary/${initialValue.domicile_certificate}`}
                alt="Domicile Certificate"
                className="img-fluid mt-2"
              />
              <div className="eyeV">
                <FaEye />
              </div>
            </div>
          )}
        </div>

        {/* Income Certificate */}
        <div className="form-group col-4">
          <label htmlFor="incomeCertificate">Upload Income Certificate:</label>
          <input
            type="file"
            className="form-control"
            id="incomeCertificate"
            name="income_certificate"
            onChange={onChangeHandleImage}
          />
          <p>File Supported: jpg, jpeg, webp, png, pdf</p>
          {initialValue.income_certificate && (
            <div
              className="perentEye d-flex justify-content-between align-items-center"
              onClick={() => {
                clickImgShow(initialValue.income_certificate, "Income Certificate");
              }}
            >
              <img
                src={`https://api.paypandabnk.com/api/cloudinary/${initialValue.income_certificate}`}
                alt="Income Certificate"
                className="img-fluid mt-2"
              />
              <div className="eyeV">
                <FaEye />
              </div>
            </div>
          )}
        </div>

        {/* Gap-Year Affidavit */}
        <div className="form-group col-4">
          <label htmlFor="gapYearAffidavit">Upload Gap-Year Affidavit:</label>
          <input
            type="file"
            className="form-control"
            id="gapYearAffidavit"
            name="gap_year_affidavit"
            onChange={onChangeHandleImage}
          />
          <p>File Supported: jpg, jpeg, webp, png, pdf</p>
          {initialValue.gap_year_affidavit && (
            <div
              className="perentEye d-flex justify-content-between align-items-center"
              onClick={() => {
                clickImgShow(initialValue.gap_year_affidavit, "Gap-Year Affidavit");
              }}
            >
              <img
                src={`https://api.paypandabnk.com/api/cloudinary/${initialValue.gap_year_affidavit}`}
                alt="Gap-Year Affidavit"
                className="img-fluid mt-2"
              />
              <div className="eyeV">
                <FaEye />
              </div>
            </div>
          )}
        </div>

        {/* Original ID */}
        <div className="form-group col-4">
          <label htmlFor="originalId">Upload Original ID (Aadhaar or Government ID):</label>
          <input
            type="file"
            className="form-control"
            id="originalId"
            name="original_id"
            onChange={onChangeHandleImage}
          />
          <p>File Supported: jpg, jpeg, webp, png, pdf</p>
          {initialValue.original_id && (
            <div
              className="perentEye d-flex justify-content-between align-items-center"
              onClick={() => {
                clickImgShow(initialValue.original_id, "Original ID");
              }}
            >
              <img
                src={`https://api.paypandabnk.com/api/cloudinary/${initialValue.original_id}`}
                alt="Original ID"
                className="img-fluid mt-2"
              />
              <div className="eyeV">
                <FaEye />
              </div>
            </div>
          )}
        </div>

        {/* Bonds */}
        <div className="form-group col-4">
          <label htmlFor="bonds">Upload Bonds:</label>
          <input
            type="file"
            className="form-control"
            id="bonds"
            name="bonds"
            onChange={onChangeHandleImage}
          />
          <p>File Supported: jpg, jpeg, webp, png, pdf</p>
          {initialValue.bonds && (
            <div
              className="perentEye d-flex justify-content-between align-items-center"
              onClick={() => {
                clickImgShow(initialValue.bonds, "Bonds");
              }}
            >
              <img
                src={`https://api.paypandabnk.com/api/cloudinary/${initialValue.bonds}`}
                alt="Bonds"
                className="img-fluid mt-2"
              />
              <div className="eyeV">
                <FaEye />
              </div>
            </div>
          )}
        </div>

        {/* Passport Size Photographs */}
        <div className="form-group col-4">
          <label htmlFor="passportSizePhotos">Upload Passport Size Photographs (Min 30):</label>
          <input
            type="file"
            className="form-control"
            id="passportSizePhotos"
            name="passport_size_photos"
            multiple
            onChange={onChangeHandleImage}
          />
          <p>File Supported: jpg, jpeg, webp, png</p>
          {initialValue.passport_size_photos && (
            <div
              className="perentEye d-flex justify-content-between align-items-center"
              onClick={() => {
                clickImgShow(initialValue.passport_size_photos, "Passport Size Photographs");
              }}
            >
              <img
                src={`https://api.paypandabnk.com/api/cloudinary/${initialValue.passport_size_photos}`}
                alt="Passport Size Photographs"
                className="img-fluid mt-2"
              />
              <div className="eyeV">
                <FaEye />
              </div>
            </div>
          )}
        </div>

        {/* Post Card Size Photograph */}
        <div className="form-group col-4">
          <label htmlFor="postCardSizePhoto">Upload Post Card Size Photograph:</label>
          <input
            type="file"
            className="form-control"
            id="postCardSizePhoto"
            name="post_card_size_photo"
            onChange={onChangeHandleImage}
          />
          <p>File Supported: jpg, jpeg, webp, png</p>
          {initialValue.post_card_size_photo && (
            <div
              className="perentEye d-flex justify-content-between align-items-center"
              onClick={() => {
                clickImgShow(initialValue.post_card_size_photo, "Post Card Size Photograph");
              }}
            >
              <img
                src={`https://api.paypandabnk.com/api/cloudinary/${initialValue.post_card_size_photo}`}
                alt="Post Card Size Photograph"
                className="img-fluid mt-2"
              />
              <div className="eyeV">
                <FaEye />
              </div>
            </div>
          )}
        </div>

        {/* Submit and Back Buttons */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <button
            type="button"
            onClick={backButton}
            style={{ backgroundColor: "#2E3191" }}
            className="btn btn-secondary text-white"
          >
            Back
          </button>
          <button
            type="button"
            onClick={submitData}
            disabled={btnDisable}
            className={`${
              btnDisable ? "btn btn-success not-allowed" : "btn btn-success"
            } text-white`}
          >
            Submit
          </button>
          {btnDisable && (
            <button
              type="button"
              onClick={() => {
                setState(5);
                window.localStorage.setItem("steps", 5);
              }}
              disabled={backBtnFun}
              className={`${
                backBtnFun ? "btn btn-info not-allowed" : "btn btn-primary"
              } text-white`}
            >
              Next
            </button>
          )}
        </div>
      </form>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <ModalImgShow handleClose={handleClose} cantain={cantain} />
      </Modal>

      <ToastContainer />
    </div>
  );
};
