

function ClossingDisput() {
    return (
        <>
            <div className="PageHeading">
                <h1>Closed Tickets</h1>
            </div>
        </>
    )
}
export default ClossingDisput