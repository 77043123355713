import CreditCardPayment from "../../../components/admin/creditCardPayment/CreditCardPayment"


function CreditCardPaymentPage() {
    return (
        <>
            <CreditCardPayment />
        </>
    )
}
export default CreditCardPaymentPage