import Header from "../../components/list-enquiry/Header";
import ListComponent from "../../components/list-enquiry/ListEnquiry";

function ListEnquiryPage() {
  return (
    <div className="card">
      <Header/>
      <ListComponent />
    </div>
  );
}
export default ListEnquiryPage;