import React, { useState } from 'react';

const data = [
  {
    transactionDate: '01-08-2024 10:30:00 AM',
    studentName: 'John Doe',
    studentID: '123456',
    course: 'Computer Science',
    amount: '$500',
    paymentMethod: 'Credit Card',
    status: 'Completed',
  },
  {
    transactionDate: '30-07-2024 02:15:00 PM',
    studentName: 'Jane Smith',
    studentID: '789012',
    course: 'Mathematics',
    amount: '$400',
    paymentMethod: 'PayPal',
    status: 'Pending',
  },
  {
    transactionDate: '29-07-2024 11:45:00 AM',
    studentName: 'Michael Brown',
    studentID: '345678',
    course: 'Physics',
    amount: '$450',
    paymentMethod: 'Debit Card',
    status: 'Completed',
  },
];

const MyTransaction = () => {
  const [searchInputs, setSearchInputs] = useState({
    transactionDate: '',
    studentName: '',
    studentID: '',
    course: '',
    amount: '',
    paymentMethod: '',
    status: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearchInputs({
      ...searchInputs,
      [name]: value,
    });
  };

  const filteredData = data.filter((item) =>
    Object.keys(searchInputs).every((key) =>
      item[key].toLowerCase().includes(searchInputs[key].toLowerCase())
    )
  );

  return (
    <table>
      <thead>
        <tr>
          {Object.keys(searchInputs).map((key) => (
            <th key={key}>
              <input
                type="text"
                name={key}
                placeholder="Search"
                className='form-control'
                value={searchInputs[key]}
                onChange={handleInputChange}
              />
            </th>
          ))}
        </tr>
        <tr>
          <th>Transaction Date</th>
          <th>Student Name</th>
          <th>Student ID</th>
          <th>Course</th>
          <th>Amount</th>
          <th>Payment Method</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {filteredData.map((item, index) => (
          <tr key={index}>
            <td>{item.transactionDate}</td>
            <td>{item.studentName}</td>
            <td>{item.studentID}</td>
            <td>{item.course}</td>
            <td>{item.amount}</td>
            <td>{item.paymentMethod}</td>
            <td>{item.status}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default MyTransaction;
