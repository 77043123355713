import React, { useState } from 'react';

const data = [
  {
    createdTime: '30-07-2024 01:06:52 PM',
    name: 'success led tv',
    mobilePhone: '9944254893',
    project: 'Arts',
    leadMedium: 'Website',
    leadStatus: 'New Lead',
    assignedTo: 'Raju Rsoft',
  },
  {
    createdTime: '29-07-2024 05:42:25 PM',
    name: 'Jagadish',
    mobilePhone: '9952408929',
    project: 'Commerce',
    leadMedium: 'Website',
    leadStatus: 'New Lead',
    assignedTo: 'Raju Rsoft',
  },
  {
    createdTime: '29-07-2024 02:16:59 PM',
    name: 'Sowmya',
    mobilePhone: '8508284336',
    project: 'Arts',
    leadMedium: 'Website',
    leadStatus: 'New Lead',
    assignedTo: 'Raju Rsoft',
  },
];

const MyEnquiryList = () => {
  const [searchInputs, setSearchInputs] = useState({
    createdTime: '',
    name: '',
    mobilePhone: '',
    project: '',
    leadMedium: '',
    leadStatus: '',
    assignedTo: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearchInputs({
      ...searchInputs,
      [name]: value,
    });
  };

  const filteredData = data.filter((item) =>
    Object.keys(searchInputs).every((key) =>
      item[key].toLowerCase().includes(searchInputs[key].toLowerCase())
    )
  );

  return (
    <table>
      <thead>
        <tr>
          {Object.keys(searchInputs).map((key) => (
            <th key={key}>
              <input
                className='form-control'
                type="text"
                name={key}
                placeholder="Search"
                value={searchInputs[key]}
                onChange={handleInputChange}
              />
            </th>
          ))}
        </tr>
        <tr>
          <th>Created Time</th>
          <th>Name</th>
          <th>Mobile Phone</th>
          <th>Stream</th>
          <th>Lead Medium</th>
          <th>Lead Status</th>
          <th>Assigned To</th>
        </tr>
      </thead>
      <tbody>
        {filteredData.map((item, index) => (
          <tr key={index}>
            <td>{item.createdTime}</td>
            <td>{item.name}</td>
            <td>{item.mobilePhone}</td>
            <td>
              <button className="project">{item.project}</button>
            </td>
            <td>
              <button className="medium">{item.leadMedium}</button>
            </td>
            <td>{item.leadStatus}</td>
            <td>{item.assignedTo}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default MyEnquiryList;
