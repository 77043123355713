import { FaArrowDownLong, FaArrowUpLong } from "react-icons/fa6";
import imsgChart from '../../../asesets/adminImage/chart.png';
import { Chart as ChartJS } from "chart.js/auto";
import { Bar, Doughnut, Line, PolarArea } from "react-chartjs-2";
import DasBoardSlideProduct from "./dasboardSlideProduct/DasBoardSlideProduct";
import { useEffect, useState, useRef } from "react";
import { Getprofile, dateGet, generateOtpForTpin, verifyOtpForTpin, generatenewTpin, resendOtpsCustomer, generateOtpForResendTpin } from "../../../api/login/Login";
import { TiTick } from 'react-icons/ti';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './Dashboard.css';
import image from "../../../asesets/logo/PayPanda_logo_Final-09-e1670775011263.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getUserTpinStatus } from "../../../utils/localStorage";
import MyEnquiryList from "./MyEnquiryList";
import MyTransaction from "./MyTransaction";
import { useNavigate } from "react-router-dom";


function DasBoardCheck() {
    const [data, setData] = useState({});

    const [number, setNumber] = useState('');
    const [loader1, setLoader1] = useState(false);
    const [loader, setLoader] = useState(false);
    const [newPin, setNewPin] = useState('');
    const [confirmPin, setConfirmPin] = useState('');
    const [pinError, setPinError] = useState(false);
    const [confirmError, setConfirmError] = useState(false);

    const [userTpinstatus, setUserTpinstatus] = useState(null);
    const [dataprofile, setDataprofile] = useState(null);
    const [message1, setMessage1] = useState('');
    const [error1, setError1] = useState('');
    const [loading, setLoading] = useState(false);
    const [message2, setMessage2] = useState('');
    const [error2, setError2] = useState('');
    const [message3, setMessage3] = useState('');
    const [error3, setError3] = useState('');
    const storedUserid = localStorage.getItem('userIdToken');
    console.log("storedUserid", storedUserid)
    const [timeLeft, setTimeLeft] = useState(180);
    const [isTimerEnded, setIsTimerEnded] = useState(false);
    const [resendLoading, setResendLoading] = useState(false);
    const [initializing, setInitializing] = useState(true);
    const [modalVisibility, setModalVisibility] = useState({
        showModal: false,
        showModal2: false,
        showModal3: false,
        showOverlay: false
    })

    const currentDates = async () => {
        const date = new Date();
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let currentDate = `${day}-${month}-${year}`;

        try {
            const res = await dateGet(currentDate);
            setData(res?.data?.data);
        } catch (error) {
            console.error(error);
        }
    };



    console.log("userTpinstatus", userTpinstatus)
    console.log("userTpinstatustype", typeof (userTpinstatus))
    const handleGetPin = () => {
        console.log("Number entered:", number);

    };

    const handleGetPin2 = () => {
        console.log('Generating Tpin for number:', number);
        alert(`Generated Tpin for ${number}`);
    };
    const handleCloseModal1 = () => {
        setModalVisibility(prev => ({ ...prev, showModal: false, showOverlay: false }));
        setMessage1('');
        setError1('');
    };

    const handleOpenModal2 = () => {
        setModalVisibility(prev => ({ ...prev, showModal2: true, showOverlay: true }));
    };

    const handleCloseModal2 = () => {
        setModalVisibility(prev => ({ ...prev, showModal2: false, showOverlay: false }));
    };

    const handleOpenModal3 = () => {
        setModalVisibility(prev => ({ ...prev, showModal3: true, showOverlay: true }));
    };

    const handleCloseModal3 = () => {
        setModalVisibility(prev => ({ ...prev, showModal3: false, showOverlay: false }));
    };
    const handleSubmitmodal3 = (e) => {
        setModalVisibility(prev => ({ ...prev, showModal3: false, showModal2: true }));
    }
    // const handleSubmitmodal2 = (e) => {
    //     handleCloseModal2();
    //     setShowOverlay(false)

    // }
    const handleNewPinChange = (e) => {
        const pin = e.target.value;
        if (/^\d{0,6}$/.test(pin)) {
            setNewPin(pin);
            setPinError(pin.length !== 4);
        } else {
            setPinError(true);
        }
    };

    const handleConfirmPinChange = (e) => {
        const pin = e.target.value;
        if (/^\d{0,6}$/.test(pin)) {
            setConfirmPin(pin);
            setConfirmError(pin.length !== 4 || pin !== newPin);
        } else {
            setConfirmError(true);
        }
    };

    useEffect(() => {
        currentDates();
        const storedTpinStatus = localStorage.getItem('userTpinstatus');
        setUserTpinstatus(storedTpinStatus);
    }, []);

    useEffect(() => {
        const initializeModals = () => {
            if (userTpinstatus === "CV") {

                setModalVisibility({
                    showModal: false,
                    showModal2: false,
                    showModal3: false,
                    showOverlay: false
                });
            } else if (userTpinstatus === "NP" || userTpinstatus === null || userTpinstatus === undefined || userTpinstatus === '' || userTpinstatus == "undefined") {

                setModalVisibility({
                    showModal: true,
                    showModal2: false,
                    showModal3: false,
                    showOverlay: true
                });
            } else if (userTpinstatus === "OV") {

                setModalVisibility({
                    showModal: false,
                    showModal2: true,
                    showModal3: false,
                    showOverlay: true
                });
            } else {

                setModalVisibility({
                    showModal: false,
                    showModal2: false,
                    showModal3: false,
                    showOverlay: false
                });
            }
        };


        if (userTpinstatus !== null) {
            initializeModals();
        }
    }, [userTpinstatus]);


    const handleSubmitmodal1 = async () => {
        setLoader1(true);

        try {
            const response = await generateOtpForTpin({});
            setLoader1(false);

            if (response && response.data && response.data.error === false) {
                toast.success('TPIN generated successfully');
                console.log("tpin generated");
                setMessage1(response.data.message);
                handleCloseModal1();
                handleOpenModal3();
            } else {
                setError1("Failed to generate TPIN");
                toast.error('Failed to generate TPIN');
            }
        } catch (error) {
            setLoader1(false);
            setError1('Failed to generate OTP for Tpin');
            toast.error('Failed to generate TPIN');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (newPin.length !== 4 || confirmPin.length !== 4 || newPin !== confirmPin) {
            setConfirmError(true);
            return;
        }

        setLoader(true);

        setMessage3('');
        setError3('');

        try {
            const response = await generatenewTpin({
                user_id: storedUserid,
                genTpin: newPin,
                confirmTpin: confirmPin
            });
            if (response?.data?.statusCode == '200') {
                getUserTpinStatus("CV")
                setMessage3('New TPIN generated successfully.');
                toast.success('New TPIN generated successfully!');
                handleCloseModal2();
            } else {
                setError3('Failed to generate TPIN.');
                toast.error('Failed to generate TPIN.');
            }
        } catch (error) {
            setError3('Failed to generate TPIN.');
            toast.error('Failed to generate TPIN.');
        } finally {
            setLoader(false);
        }
    };



    const isSubmitDisabled = pinError || confirmError || newPin.length !== 4 || confirmPin.length !== 4 || newPin !== confirmPin;





    const length1 = 6;
    const [otp1, setOtp1] = useState(new Array(length1).fill(''));
    const inputs1 = useRef([]);

    const otpChandleChange1 = (index, value) => {
        const newOtp = [...otp1];
        if (value.length === 2) {
            return;
        }
        newOtp[index] = value;
        setOtp1(newOtp);

        if (value && index < length1 - 1 && inputs1.current[index + 1]) {
            inputs1.current[index + 1].focus();
        }
    };

    const handleKeyDown1 = (index, e) => {
        if (e.key === 'Backspace' && !otp1[index] && index > 0 && inputs1.current[index - 1]) {
            inputs1.current[index - 1].focus();
        }
    };

    const isAllInputsFilled = !otp1.includes('');

    const handleSubmitOTP = async () => {
        setLoading(true);
        setMessage2('');
        setError2('');

        try {
            const response = await verifyOtpForTpin({ user_id: storedUserid, otp: otp1.join('') });
            console.log("otpres", response);

            if (response?.data?.statusCode == '200') {
                getUserTpinStatus("OV");
                setMessage2('OTP verified successfully.');
                toast.success('OTP verified successfully.');
                handleSubmitmodal3();
            } else if (response?.message === 'Invalid OTP') {
                setError2('Invalid OTP.');
                toast.error('Invalid OTP.');
            } else {
                setError2('Failed to verify OTP.');
                toast.error('Failed to verify OTP.');
            }
        } catch (error) {
            console.error("otperr", error);

            if (error.response?.data?.message === 'Invalid OTP') {
                setError2('Invalid OTP.');
                toast.error('Invalid OTP.');
            } else {
                setError2('Failed to verify OTP.');
                toast.error('Failed to verify OTP.');
            }
        } finally {
            setLoading(false);
        }
    };



    const dummyInputs = new Array(length1).fill(null);
    function processCustomerMobile() {

        var value = window.localStorage.getItem('regisNumber');


        var mobile = '';


        if (value) {

            if (value.charAt(0) == '+' || value.charAt(0) == '0') {
                mobile = value.replace(/[^a-zA-Z0-9+]/g, "").substr(3);
            } else {
                mobile = value.replace(/[^a-zA-Z0-9]/g, "");
            }
        } else {
            console.log('No registration number found in localStorage.');
        }


        return mobile;
    }


    var customerMobile = processCustomerMobile();


    useEffect(() => {
        console.log(" timeLeft:", timeLeft, "isTimerEnded:", isTimerEnded);
        let timer;
        if (timeLeft > 0 && !isTimerEnded) {
            timer = setTimeout(() => {
                console.log("Decreasing timeLeft:", timeLeft - 1);
                setTimeLeft(prevTime => prevTime - 1);
            }, 1000);
        } else if (timeLeft === 0 && !isTimerEnded) {
            setIsTimerEnded(true);
            console.log("Timer ended");
        }
        return () => clearTimeout(timer);
    }, []);

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    const handleResendOTP = async () => {
        setResendLoading(true);
        setError2('');
        setMessage2('');

        try {
            const response = await resendOtpsCustomer({ customer_mobile: customerMobile, user_id: storedUserid });
            if (response.status === 200) {
                setMessage2('OTP has been resent successfully.');
                setTimeLeft(180);
                setIsTimerEnded(false);
            } else {
                setError2('Failed to resend OTP.');
            }
        } catch (error) {
            setError2('Failed to resend OTP.');
        } finally {
            setResendLoading(false);
        }
    };


    // const getDataProfile = async () => {
    //     try {
    //         const res = await Getprofile();
    //         setDataprofile(res?.data?.data)
    //     } catch (error) {

    //     }
    // }
    // useEffect(() => {
    //     getDataProfile()


    // }, [])
    console.log('data', data)

    const  navigate = useNavigate()
    const chengeRoute = () => {
        navigate("/registrationComplete");
    }

    return (
        <>
            {modalVisibility.showOverlay && <div className="overlay modalpinoverlayclass"></div>}
            <div className="ContentArea">
                <div className="card">
                    <div className="card-header"><span>Hi, Welcome To back!</span></div>
                    {/* <DasBoardSlideProduct /> */}
                    <div className="card">
                      <button className="blinking-button" onClick={chengeRoute}>Click Here To Complete Your Registration !</button>
                        
                    </div>
                    <div className="card">
                        <h2 style={{ padding: "10px" }}>My Admission Enquiries </h2>

                        <MyEnquiryList />
                    </div>

                    <div className="card" style={{ marginTop: "20px 0" }}>
                        <h2 style={{ padding: "10px" }}>My Transactions                         </h2>
                        <MyTransaction />
                    </div>

                </div>
            </div>




            <ToastContainer />
        </>
    );
}

export default DasBoardCheck;
