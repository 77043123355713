import AccountReport from "../../../components/admin/accountReport/AccountReport"


function AccountReportPage() {
    return (
        <>
            <AccountReport />
        </>
    )
}
export default AccountReportPage