import React from 'react'
import AepsDeviceDrivers from '../../../components/admin/aepsDeviceDrivers/AepsDeviceDrivers'

const AepsDevicePage = () => {
  return (
    <>
      <AepsDeviceDrivers/>
    </>
  )
}

export default AepsDevicePage
