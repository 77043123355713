import OpenDisput from "../../../../components/admin/disput/openDisput/OpenDisput"


function OpenDisputPage() {
    return (
        <>
            <OpenDisput />
        </>
    )
}
export default OpenDisputPage