import { Link } from "react-router-dom"
import Loader from "../../../common/loader/Loader"
import { useEffect, useState } from "react";
import { bankListApi, payoutAccountEnquiry, payoutBankDelete, payoutBenefiaries, userValidate } from "../../../api/login/Login";
import { MdDelete } from "react-icons/md";
import AepsPinModal from "./aepsPinModal/AepsPinModal";
import { ToastContainer, toast } from "react-toastify";
import { Popconfirm } from 'antd';

function AepsPayout() {
    const [loading, setLoading] = useState(false);
    const [modalShow, setModalShow] = useState(false);

    const [data, setData] = useState(null)
    // console.log(data);
    const [walletData, setWalletData] = useState(null)
    const [selectedItem, setSelectedItem] = useState(null);
    console.log(selectedItem);

    const [initialValue, setInitialValue] = useState({
        user_id: '',
        amount: '',
        transId: '',
        paymentMode: 'NE',
        tpin: ''
    })

    // console.log(initialValue);

    const handleChangeSet = (e) => {
        const clone = { ...initialValue }
        const value = e.target.value
        const name = e.target.name
        clone[name] = value
        setInitialValue(clone)
    }

    const aepsPayoutList = async () => {
        setLoading(true)
        try {
            const res = await payoutBenefiaries()
            setData(res?.data?.data)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    const toastSuccessMessage = (message) => {
        toast.success(`${message}`, {
            position: "top-center",
        });
    };
    const toastErrorMessage = (message) => {
        toast.error(`${message}`, {
            position: "top-center",
        });
    };

    const enquiryStatus = async (id) => {
        try {
            const res = await payoutAccountEnquiry(id)
            // console.log(res?.data);
            if (res?.data?.error == false) {
                toastSuccessMessage(res?.data?.data?.message)
                aepsPayoutList()
            } else {
                toastErrorMessage(res?.data?.data?.message)
            }
        } catch (error) {

        }
    }

    const deletePayoutDelete = async (id) => {
        try {
            const res = await payoutBankDelete(id)
            if (res?.data?.error == false) {
                toastSuccessMessage(res?.data?.data?.message)
                aepsPayoutList()
            } else {
                toastErrorMessage(res?.data?.data?.message)
            }
        } catch (error) {

        }
    }
    const confirm = (id) => {
        console.log(id);
        deletePayoutDelete(id)
    }

    const changeRadioObject = (e, item) => {
        setSelectedItem(item);
    }

    useEffect(() => {
        if (data && data.length > 0) {
            setSelectedItem(data[0]); // Select the first item by default
        }
    }, [data]);

    const aepsWallet = async () => {
        try {
            const res = await userValidate()
            setWalletData(res?.data)
        } catch (error) {

        }
    }

    const handleChangeChanel = (str) => {
        const clone = { ...initialValue, paymentMode: str }
        setInitialValue(clone);
    }

    const handleSumitPayemt = () => {
        const clone = { ...initialValue }
        // console.log(clone);
    }

    useEffect(() => {
        aepsPayoutList()
        aepsWallet()
    }, [])

    return (
        <>
            {loading && <Loader />}
            <div className="PageHeading">
                <h1>AEPS Payout Details</h1>
            </div>

            <div className="ContentArea">
                <div className="card">
                    <div className="card-header">AEPS Payout Details<span style={{ color: 'red' }}> <Link to="/add-aeps-payout-account" class="btn btn-success">Add Payout Account</Link> </span></div>
                    <div className="card-body">
                        <div id="myTable_wrapper" className="dataTables_wrapper no-footer">

                            <div id="myTable_processing" className="dataTables_processing" style={{ display: 'none' }}>Processing...</div>
                            <table className="table table-striped table-bordered table-hover display dataTable no-footer dtr-inline collapsed" role="grid" aria-describedby="myTable_info" >
                                <thead>
                                    <tr role="row">
                                        <th className="sorting" >Select</th>
                                        <th className="sorting">
                                            Account Holder Name
                                        </th>
                                        <th className="sorting">
                                            Mobile No
                                        </th>
                                        <th className="sorting" >
                                            Account No
                                        </th>
                                        <th className="sorting" >Bank</th>
                                        <th className="sorting" >IFSC Code</th>
                                        <th className="sorting" >Current Status</th>
                                        {/* <th className="sorting" >Is Primary</th> */}
                                        <th className="sorting" >Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data && data?.map((item, index) => {
                                        return <tr key={item?._id} className={item === selectedItem ? 'selected-row' : ''}>
                                            <td valign="top" className={item === selectedItem ? 'selected-row' : ''}>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="flexRadioDefault"
                                                        checked={item === selectedItem}
                                                        onChange={(e) => changeRadioObject(e, item)}
                                                    />
                                                    {/* <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                        Default radio
                                                    </label> */}
                                                </div>
                                            </td>
                                            <td valign="top" >{item?.bank_account_name}</td>
                                            <td valign="top" >{item?.mobile_number}</td>
                                            <td valign="top" >{item?.bank_account_number}</td>
                                            <td valign="top" >{item?.bank_name}</td>
                                            <td valign="top" >{item?.bank_ifsc}</td>
                                            {item?.status == 1 ? < td valign="top" ><button type="button" class="btn btn-warning" onClick={() => enquiryStatus(item?._id)}>Pending</button></td> : ''}
                                            {item?.status == 2 ? < td valign="top" ><button type="button" class="btn btn-success" onClick={() => enquiryStatus(item?._id)}>Success</button></td> : ''}
                                            {item?.status == 3 ? < td valign="top" ><button type="button" class="btn btn-danger" onClick={() => enquiryStatus(item?._id)}>Failed</button></td> : ''}
                                            {/* <td valign="top" className="dataTables_empty">{item?.isPrimary == false ? 'No' : 'Yes'}</td> */}

                                            <td valign="top" >
                                                <div className="delete-icon">
                                                    <Popconfirm
                                                        title="Delete"
                                                        description="Are you sure to delete ?"
                                                        okText="Yes"
                                                        cancelText="No"
                                                        onConfirm={() => confirm(item?._id)}
                                                    >
                                                        <MdDelete />
                                                    </Popconfirm>
                                                    {/* <Popconfirm
                                                        title="Delete"
                                                        className={`deletbtnIco ${item?.isVerified && 'deletbtnIco1'}`}
                                                        description="Are you sure to delete ?"
                                                        onConfirm={() => confirm(item?.recipient_id)}
                                                        onCancel={cancel}
                                                        okText="Yes"

                                                        cancelText="No"
                                                    >

                                                    </Popconfirm> */}

                                                </div>
                                            </td>
                                        </tr>
                                    })}

                                </tbody>
                            </table>
                        </div>

                        <form action="" method="post" name="frmReport" id="frmReport">
                            <input type="hidden" id="hidID" name="hidID" />
                            <div className="form-row" style={{ alignItems: 'end' }}>
                                {/* <div className="form-group col-md-4">
                                    <label htmlFor="txtUserId">Min Amount</label>
                                    <input type="number" name="min_amt" id="account_no" className="form-control" value={filterInitial.min_amt} onChange={handleChange} />
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="txtUserId">Max Amount</label>

                                    <input type="number" name="max_amt" id="account_no" className="form-control" value={filterInitial.max_amt} onChange={handleChange} />
                                </div> */}

                                <div className="form-group col-md-4">
                                    <label htmlFor="txtUserId">AEPS Blance <span style={{ color: 'red' }}>*</span></label>
                                    <input type="number" disabled name="start_date" value={walletData?.aeps_wallet} id="account_no" className="form-control" />
                                </div>
                                <div className="form-group col-md-8">
                                    <label htmlFor="txtUserId">Payment Mode <span style={{ color: 'red' }}>*</span></label>
                                    <div className="set-head-mate">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="channel" defaultChecked id="NEFT" onChange={(e) => handleChangeChanel('NE')} />
                                            <label className="form-check-label" htmlFor="NEFT">
                                                NEFT
                                            </label>
                                        </div>
                                        <div className="form-check mr-3">
                                            <input className="form-check-input" type="radio" name="channel" id="IMPS" onChange={(e) => handleChangeChanel('PA')} />
                                            <label className="form-check-label" htmlFor="IMPS">
                                                IMPS
                                            </label>
                                        </div>
                                        <div className="form-check mr-3">
                                            <input className="form-check-input" type="radio" name="channel" id="RTGS" onChange={(e) => handleChangeChanel('RT')} />
                                            <label className="form-check-label" htmlFor="RTGS">
                                                RTGS
                                            </label>
                                        </div>
                                        <div className="form-check mr-3">
                                            <input className="form-check-input" type="radio" name="channel" id="FundTransfer" onChange={(e) => handleChangeChanel('FT')} />
                                            <label className="form-check-label" htmlFor="FundTransfer">
                                                Fund Transfer
                                            </label>
                                        </div>
                                        <div className="form-check mr-3">
                                            <input className="form-check-input" type="radio" name="channel" id="CorporateCheques" onChange={(e) => handleChangeChanel('CC')} />
                                            <label className="form-check-label" htmlFor="CorporateCheques">
                                                Corporate Cheques
                                            </label>
                                        </div>
                                        <div className="form-check mr-3">
                                            <input className="form-check-input" type="radio" name="channel" id="DD" onChange={(e) => handleChangeChanel('DD')} />
                                            <label className="form-check-label" htmlFor="DD">
                                                Demand Draft
                                            </label>
                                        </div>
                                    </div>

                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="txtUserId">Amount to be Settled <span style={{ color: 'red' }}>*</span></label>
                                    <input type="number" placeholder="Enter 100 - 1,00,000" name="amount" value={initialValue?.amount} id="account_no" className="form-control" onChange={handleChangeSet} />
                                </div>
                                <div className="form-group text-align-center col-md-12">
                                    <label>&nbsp;</label>
                                    <button type="button" className="btn btn-primary mr-3" disabled={!initialValue?.amount} onClick={() => setModalShow(true)}>Proceed</button>
                                    {/* <button type="button" className="btn btn-warning">Reset</button> */}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <AepsPinModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    handleSumitPayemt={handleSumitPayemt}
                    initialValue={initialValue}
                    selectedItem={selectedItem}
                    toastSuccessMessage={toastSuccessMessage}
                    toastErrorMessage={toastErrorMessage}
                />
            </div>
            <ToastContainer />
        </>
    )
}
export default AepsPayout