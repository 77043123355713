import AirtelCmsReport from "../../../components/admin/airtelCmsReport/AirtelCmsReport"


function AirtelCmsReportPage() {
    return (
        <>
            <AirtelCmsReport />
        </>
    )
}
export default AirtelCmsReportPage