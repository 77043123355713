import React, { useEffect, useState } from 'react';
import { ListEnquiryApi } from '../../api/login/Login';

const ListEnquiry = () => {
  const [searchInputs, setSearchInputs] = useState({
    createdTime: '',
    name: '',
    mobilePhone: '',
    stream: '',
    leadMedium: '',
    leadStatus: '',
    assignedTo: '',
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(8);
  const [data, setData] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearchInputs({
      ...searchInputs,
      [name]: value,
    });
    setCurrentPage(1); // Reset to first page on search
  };

  const filteredData = data?.filter((item) =>
    Object.keys(searchInputs).every((key) => {
      const searchValue = searchInputs[key].toLowerCase();
      const itemValue = item[key]?.toLowerCase() || '';
      return itemValue.includes(searchValue);
    })
  );

  // Calculate the displayed items based on pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const getData = async (num) => {
    try {
      const res = await ListEnquiryApi({ page: num, count: 10 });
      setData(res.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    getData(0);
  }, []);

  return (
    <div>
      <table style={{ width: '1300px', margin: '10px auto' }}>
        <thead>
          {/* <tr>
            {Object.keys(searchInputs).map((key) => (
              <th key={key}>
                <input
                  type="text"
                  className='form-control'
                  name={key}
                  placeholder="Search"
                  value={searchInputs[key]}
                  onChange={handleInputChange}
                />
              </th>
            ))}
          </tr> */}
          <tr>
            <th>Created Time</th>
            <th>Name</th>
            <th>Mobile Phone</th>
            <th>Stream</th>
            <th>Lead Medium</th>
            <th>Lead Status</th>
            <th>Assigned To</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item, index) => (
            <tr key={index}>
              <td>{item.createdAt}</td>
              <td>{item.user_id?.name}</td>
              <td>{item.mobile}</td>
              <td>{item.stream_id?.length > 0 && item?.stream_id?.map((item) =>
                <span>{item.name}</span>
              )}</td>
              <td>{item.leadMedium}</td>
              <td>{item.leadStatus}</td>
              <td>{item.assignTo[0]?.name}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div style={{ textAlign: 'center', margin: '20px 0' }}>
        <button className='btn btn-primary' onClick={handlePrevPage} disabled={currentPage === 1}>
          Previous
        </button>
        <span style={{ margin: '0 10px' }}>
          Page {currentPage} of {totalPages}
        </span>
        <button className='btn btn-primary' onClick={handleNextPage} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>
    </div>
  );
};

export default ListEnquiry;
