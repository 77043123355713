import AdharPayReport from "../../../components/admin/adharPayReport/AdharPayReport"


function AdharPayReportPage() {
    return (
        <>
            <AdharPayReport />
        </>
    )
}
export default AdharPayReportPage