import PaymentWithDrowReport from "../../../components/admin/paymentWithDrowReport/PaymentWithDrowReport"


function PaymentWithDrowReportPage() {
    return (
        <>
            <PaymentWithDrowReport />
        </>
    )
}
export default PaymentWithDrowReportPage