import React, { useEffect, useState } from "react";
import "../cartificate/certificate.css";
import logo from "../../../asesets/logo/PayPanda_logo_Final-09-e1670775011263.png";
import { authCertificate } from "../../../api/login/Login";
export default function Certificate() {
  const [state, setState] = useState();
  // console.log("-----", state);
  const fetchauthCertificate = async () => {
    const response = await authCertificate();
    setState(response.data);
  };

  useEffect(() => {
    fetchauthCertificate();
  }, []);
  return (
    <div className="main mt-4">
      <div className="text-wrap">
        <div className="dateItem">
          <p>
            {state?.data?.merchant_type} Code : {state?.data?.refer_id}
          </p>
        </div>
        <div className="logo-wrap">
          <img src={logo} className="full" />
        </div>
        <div className="content">
          <h2 className="header">certificate of authorization</h2>
          <div className="certify">
            <div className="nameof">
              <small>This is to certify that</small>
              <h2>
                mr / ms <span>{state?.data?.name}</span>
              </h2>
            </div>
            <div className="nameof">
              <small>
                Is appointed as the customer service point of Pay Panda Payment
                Solution Private Limited.{" "}
              </small>
              <h2>
                <span>
                  s/o mahendra, okhla vihar, a-35, near iqbal faizi hospital,
                  south jamia nagar, india, 110025, Delhi, 226001
                </span>
              </h2>
            </div>
          </div>
          <div className="validity">
            <div className="item">
              <h2>{state?.data?.onBoardDate}</h2>
              <p>Onboarding Date </p>
            </div>

            <div className="item">
              <h2>
                {new Date().getDate() +
                  "/" +
                  new Date().getMonth() +
                  "/" +
                  new Date().getFullYear()}
              </h2>
              <p>Print Date </p>
            </div>
            <div className="item">
              <h2>{state?.data?.validDate} </h2>
              <p>valid Till</p>
            </div>
          </div>
          <div className="d-flex justify-content-between py-5">
            <div className="dateItem">
              <img
                src={`https://api.paypandabnk.com/api/cloudinary/${state?.data?.sign}`}
                alt="Aadhaar Back"
                className="img-fluid mt-2"
                width={200}
              />
              <p className="text-center">Signature</p>
            </div>
            <div className="item2">
              <span>power by</span>
              <div>
                <img src={logo} width={200} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
