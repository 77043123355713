import AepsFinoUserDasboard from "../../../components/admin/aepsFinoUserDasboard/AepsFinoUserDasboard"


function AepsFinoUserDasBoardPage() {
    return (
        <>
            <AepsFinoUserDasboard />
        </>
    )
}
export default AepsFinoUserDasBoardPage