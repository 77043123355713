import DasBoardCheck from "../../../components/admin/daseboardCheck/DasBoardChaeck"


function DaseboardCheckPage() {
    return (
        <>
            <DasBoardCheck />
        </>
    )
}
export default DaseboardCheckPage