import ClossingDisput from "../../../../components/admin/disput/clossingDisput/ClossingDisput"


function ClossingDisputPage() {
    return (
        <>
            <ClossingDisput />
        </>
    )
}
export default ClossingDisputPage