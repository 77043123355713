import CreatePin from "../../../../components/admin/settings/createPin/CreatePin"


function CreatePinPage() {
    return (
        <>
            <CreatePin />
        </>
    )
}
export default CreatePinPage