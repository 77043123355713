import PasswordChange from "../../../../components/admin/settings/passwordChange/PasswordChange"


function PasswordChagePage() {
    return (
        <>
            <PasswordChange />
        </>
    )
}
export default PasswordChagePage