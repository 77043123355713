import PackageList from "../../../../components/admin/package/PackageList/PackageList"


function PackageListPage() {
    return (
        <>
            <PackageList />
        </>
    )
}
export default PackageListPage