import React, { useState } from 'react';

const data = [
  {
    createdTime: '01-08-2024 10:30:00 AM',
    name: 'John Doe',
    mobilePhone: '123-456-7890',
    stream: 'Computer Science',
    leadMedium: 'Online',
    leadStatus: 'Reviewed',
    assignedTo: 'Alice Johnson',
  },
  {
    createdTime: '30-07-2024 02:15:00 PM',
    name: 'Jane Smith',
    mobilePhone: '234-567-8901',
    stream: 'Mathematics',
    leadMedium: 'Walk-in',
    leadStatus: 'Pending',
    assignedTo: 'Bob Brown',
  },
  {
    createdTime: '29-07-2024 11:45:00 AM',
    name: 'Michael Brown',
    mobilePhone: '345-678-9012',
    stream: 'Physics',
    leadMedium: 'Referral',
    leadStatus: 'Reviewed',
    assignedTo: 'Charlie Davis',
  },
  {
    createdTime: '01-08-2024 10:30:00 AM',
    name: 'John Doe',
    mobilePhone: '123-456-7890',
    stream: 'Computer Science',
    leadMedium: 'Online',
    leadStatus: 'Reviewed',
    assignedTo: 'Alice Johnson',
  },
  {
    createdTime: '30-07-2024 02:15:00 PM',
    name: 'Jane Smith',
    mobilePhone: '234-567-8901',
    stream: 'Mathematics',
    leadMedium: 'Walk-in',
    leadStatus: 'Pending',
    assignedTo: 'Bob Brown',
  },
  {
    createdTime: '29-07-2024 11:45:00 AM',
    name: 'Michael Brown',
    mobilePhone: '345-678-9012',
    stream: 'Physics',
    leadMedium: 'Referral',
    leadStatus: 'Reviewed',
    assignedTo: 'Charlie Davis',
  },
];

const MyEnquiryRepo = () => {
  const [searchInputs, setSearchInputs] = useState({
    createdTime: '',
    name: '',
    mobilePhone: '',
    stream: '',
    leadMedium: '',
    leadStatus: '',
    assignedTo: '',
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5); // Number of items per page

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearchInputs({
      ...searchInputs,
      [name]: value,
    });
    setCurrentPage(1); // Reset to first page on search
  };

  const filteredData = data.filter((item) =>
    Object.keys(searchInputs).every((key) =>
      item[key].toLowerCase().includes(searchInputs[key].toLowerCase())
    )
  );

  // Calculate the displayed items based on pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  return (
    <div>
      <table style={{ width: '1300px', margin: '10px auto' }}>
        <thead>
          <tr>
            {Object.keys(searchInputs).map((key) => (
              <th key={key}>
                <input
                  type="text"
                  className='form-control'
                  name={key}
                  placeholder="Search"
                  value={searchInputs[key]}
                  onChange={handleInputChange}
                />
              </th>
            ))}
          </tr>
          <tr>
            <th>Created Time</th>
            <th>Name</th>
            <th>Mobile Phone</th>
            <th>Stream</th>
            <th>Lead Medium</th>
            <th>Lead Status</th>
            <th>Assigned To</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item, index) => (
            <tr key={index}>
              <td>{item.createdTime}</td>
              <td>{item.name}</td>
              <td>{item.mobilePhone}</td>
              <td>{item.stream}</td>
              <td>{item.leadMedium}</td>
              <td>{item.leadStatus}</td>
              <td>{item.assignedTo}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div style={{ textAlign: 'center', margin: '20px 0' }}>
        <button className='btn btn-primary' onClick={handlePrevPage} disabled={currentPage === 1}>
          Previous
        </button>
        <span style={{ margin: '0 10px' }}>
          Page {currentPage} of {totalPages}
        </span>
        <button className='btn btn-primary' onClick={handleNextPage} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>
    </div>
  );
};

export default MyEnquiryRepo;
