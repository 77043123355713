import AepsWalletTrasfer from "../../../components/admin/aepsWalletTransfer/AepsWalletTransfer"


function AepsWalletsTrasferPage() {
    return (
        <>
            <AepsWalletTrasfer />
        </>
    )
}
export default AepsWalletsTrasferPage