import ComplainList from "../../../components/admin/complainList/ComplainList"


function ComplainListPage() {
    return (
        <>
            <ComplainList />
        </>
    )
}
export default ComplainListPage