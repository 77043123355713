import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getAllStream, getAlltypes, sendEnquiry } from '../../api/login/Login';
import { toast, ToastContainer } from 'react-toastify';
import Loader from '../../common/loader/Loader';
import './NewOne.css';
import Select from "react-select";

const AddEnquiryComp = () => {
  const [formData, setFormData] = useState({
    college: '',
    subject: '',
    stream_id: '',
    course_id: '',
    detail: '',
    name: '',
    mobileNumber: '',
    email: '',
    leadSource: '',
    leadMedium: '',
   
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.college) newErrors.college = "College ID is required.";
    if (!formData.subject) newErrors.subject = "Subject is required.";
    if (!formData.stream_id) newErrors.stream_id = "Stream is required.";
    if (!formData.course_id) newErrors.course_id = "Course is required.";
    if (!formData.detail) newErrors.detail = "Detail is required.";
    if (!formData.name) newErrors.name = "Name is required.";
    if (!formData.assignedTo) newErrors.assignedTo = "Assigned to is required.";
    if (!formData.mobileNumber) newErrors.mobileNumber = "Mobile number is required.";
    if (!formData.email) newErrors.email = "Email is required.";
    if (!formData.leadSource) newErrors.leadSource = "Lead Source is required.";
    if (!formData.leadMedium) newErrors.leadMedium = "Lead Medium is required.";
    if (!formData.leadStatus) newErrors.leadStatus = "Lead Status is required.";
    if (!formData.leadSubStatus) newErrors.leadSubStatus = "Lead Sub Status is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const toastSuccessMessage = () => {
    toast.success(`Your Enquiry Sent Successfully.`, {
      position: "top-center",
    });
  };

  const toastErrMessage = (str) => {
    toast.error(`${str}.`, {
      position: "top-center",
    });
  };

  const [loader, setLoader] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const maped1 = showname.map(id => id._id);
    const maped = courseName.map(id => id._id);

    setLoader(true);
    const res = await sendEnquiry({
      ...formData,
      stream_id: maped1,
      course_id: maped,
    });
    if (res?.data?.statusCode == 200) {
      toastSuccessMessage();
    } else {
      toastErrMessage(res?.data?.message);
    }
    setLoader(false);
  };

  const [showname, setShowname] = useState();
  const [showtype, setShowtype] = useState();
  const [courseName, setCourseName] = useState('');
  const [courseType, setCourseType] = useState('');

  const getAllStrea = async () => {
    try {
      const response = await getAllStream();
      if (response.data.error === false) {
        const streams = response.data.data.map(stream => ({
          ...stream,
          value: stream._id,
          name: stream.name,
          label: stream.name
        }));
        setCourseName(streams);
      } else {
        console.error('Error fetching streams:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching streams:', error);
    }
  };

  useEffect(() => {
    getAllStrea();
  }, []);

  const getAlltype = async (ids) => {
    const maped = ids.map(id => id._id);

    try {
      const response = await getAlltypes(JSON.stringify(maped));
      if (response.data.error === false) {
        const streams = response.data.data.map(stream => ({
          ...stream,
          value: stream._id,
          name: stream.name,
          label: stream.name
        }));
        setCourseType(streams);
      } else {
        console.error('Error fetching streams:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching streams:', error);
    }
  };

  const nameChange = (e) => {
    setShowname(e);
    getAlltype(e);
  };

  const typeChange = (e) => {
    setShowtype(e);
  };

  return (
    <div className="container mt-5" style={{ margin: "10px auto" }}>
      {loader ? <Loader /> : ''}
      <ToastContainer />
      <form className="row" onSubmit={handleSubmit}>
        <div className="form-group col-6">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className={`form-control ${errors.name ? 'is-invalid' : ''}`}
            required
          />
          {errors.name && <div className="invalid-feedback">{errors.name}</div>}
        </div>

        <div style={{ zIndex: '100' }} className="col-lg-6 col-md-6 col-sm-12 mb-3 form-group uk-scrollspy-inview uk-animation-slide-bottom ">
          <label htmlFor="state">Course Type<span style={{ color: 'red' }}>*</span></label>

          <Select
            isMulti
            value={showname}
            name="name"
            options={courseName}
            className="games-dropdown-2 customsection"
            classNamePrefix="select"
            onChange={nameChange}
          />
        </div>

        <div style={{ zIndex: 100 }} className="col-lg-6 col-md-6 col-sm-12 mb-3 form-group uk-scrollspy-inview uk-animation-slide-bottom">
          <label htmlFor="state">Course Name<span style={{ color: 'red' }}>*</span></label>

          <Select
            isMulti
            value={showtype}
            name="name"
            isDisabled={courseType?.length === 0 ? true : false}
            options={courseType}
            className="games-dropdown-2 customsection"
            classNamePrefix="select"
            onChange={typeChange}
          />
        </div>

        {/* <div className="form-group col-6">
          <label htmlFor="assignedTo">Assigned to</label>
          <select
            style={{ padding: "6px" }}
            id="assignedTo"
            name="assignedTo"
            value={formData.assignedTo}
            onChange={handleChange}
            className={`form-control ${errors.assignedTo ? 'is-invalid' : ''}`}
            required
          >
            <option value="">Select</option>
            <option value="User1">User1</option>
            <option value="User2">User2</option>
            <option value="User3">User3</option>
          </select>
          {errors.assignedTo && (
            <div className="invalid-feedback">{errors.assignedTo}</div>
          )}
        </div> */}

        <div className="form-group col-6">
          <label htmlFor="mobileNumber">Mobile number</label>
          <input
            type="text"
            id="mobileNumber"
            name="mobileNumber"
            value={formData.mobileNumber}
            onChange={handleChange}
            className={`form-control ${errors.mobileNumber ? 'is-invalid' : ''}`}
            required
          />
          {errors.mobileNumber && (
            <div className="invalid-feedback">{errors.mobileNumber}</div>
          )}
        </div>

        <div className="form-group col-6">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className={`form-control ${errors.email ? 'is-invalid' : ''}`}
            required
          />
          {errors.email && <div className="invalid-feedback">{errors.email}</div>}
        </div>

        {/* <div className="form-group col-6">
          <label htmlFor="leadSource">Lead Source</label>
          <select
            style={{ padding: "6px" }}
            id="leadSource"
            name="leadSource"
            value={formData.leadSource}
            onChange={handleChange}
            className={`form-control ${errors.leadSource ? 'is-invalid' : ''}`}
            required
          >
            <option value="">Select</option>
            <option value="Referral">Referral</option>
            <option value="Social Media">Social Media</option>
            <option value="Advertisement">Advertisement</option>
          </select>
          {errors.leadSource && (
            <div className="invalid-feedback">{errors.leadSource}</div>
          )}
        </div> */}

        <div className="form-group col-6">
          <label htmlFor="leadMedium">Lead Medium</label>
          <select
            style={{ padding: "6px" }}
            id="leadMedium"
            name="leadMedium"
            value={formData.leadMedium}
            onChange={handleChange}
            className={`form-control ${errors.leadMedium ? 'is-invalid' : ''}`}
            required
          >
            <option value="">Select</option>
            <option value="Email">Email</option>
            <option value="Phone">Phone</option>
            <option value="In-person">In-person</option>
          </select>
          {errors.leadMedium && (
            <div className="invalid-feedback">{errors.leadMedium}</div>
          )}
        </div>

        {/* <div className="form-group col-6">
          <label htmlFor="leadStatus">Lead Status</label>
          <select
            style={{ padding: "6px" }}
            id="leadStatus"
            name="leadStatus"
            value={formData.leadStatus}
            onChange={handleChange}
            className={`form-control ${errors.leadStatus ? 'is-invalid' : ''}`}
            required
          >
            <option value="">Select</option>
            <option value="New">New</option>
            <option value="In Progress">In Progress</option>
            <option value="Closed">Closed</option>
          </select>
          {errors.leadStatus && (
            <div className="invalid-feedback">{errors.leadStatus}</div>
          )}
        </div>

        <div className="form-group col-6">
          <label htmlFor="leadSubStatus">Lead Sub Status</label>
          <select
            style={{ padding: "6px" }}
            id="leadSubStatus"
            name="leadSubStatus"
            value={formData.leadSubStatus}
            onChange={handleChange}
            className={`form-control ${errors.leadSubStatus ? 'is-invalid' : ''}`}
            required
          >
            <option value="">Select</option>
            <option value="Contacted">Contacted</option>
            <option value="Follow-up">Follow-up</option>
            <option value="Qualified">Qualified</option>
          </select>
          {errors.leadSubStatus && (
            <div className="invalid-feedback">{errors.leadSubStatus}</div>
          )}
        </div> */}

        <div className="form-group col-6">
          <label htmlFor="college">College Name</label>
          <input
            type="text"
            id="college"
            name="college"
            value={formData.college}
            onChange={handleChange}
            className={`form-control ${errors.college ? 'is-invalid' : ''}`}
            required
          />
          {errors.college && <div className="invalid-feedback">{errors.college}</div>}
        </div>

        <div className="form-group col-6">
          <label htmlFor="subject">Subject</label>
          <input
            type="text"
            id="subject"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
            className={`form-control ${errors.subject ? 'is-invalid' : ''}`}
            required
          />
          {errors.subject && <div className="invalid-feedback">{errors.subject}</div>}
        </div>

        <div className="form-group col-6">
          <label htmlFor="detail">Detail</label>
          <textarea
            id="detail"
            name="detail"
            value={formData.detail}
            onChange={handleChange}
            className={`form-control ${errors.detail ? 'is-invalid' : ''}`}
            required
          ></textarea>
          {errors.detail && <div className="invalid-feedback">{errors.detail}</div>}
        </div>

        <button type="submit" className="btn btn-primary">Submit</button>
      </form>

    </div>
  );
};

export default AddEnquiryComp;
