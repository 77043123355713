import PaymentRequest from "../../../../components/admin/payment/paymentRequest/PaymentRequest"


function PaymentRequestPage() {
    return (
        <>
            <PaymentRequest />
        </>
    )
}
export default PaymentRequestPage