
import MyTrasactionsRepo from "../../components/Transaction/MyTrasactionsRepo"


function MyTransactionReport() {
  return <div className="card">
    <h1 style={{margin:"auto"}}>My Transactions Report List</h1>
    <MyTrasactionsRepo />
  </div>
}
export default MyTransactionReport