import QuickDhan from "../../../components/admin/quickDhan/QuickDhan"


function QucickDhanPage() {
    return (
        <>
            <QuickDhan />
        </>
    )
}
export default QucickDhanPage