import TdsCertificate from "../../../components/admin/tdsCertificate/TdsCertifiacete"


function TdsCertificatePage() {
    return (
        <>
            <TdsCertificate />
        </>
    )
}
export default TdsCertificatePage