import OtpSecurity from "../../../../components/admin/settings/otpSecurity/OtpSecurity"


function OtpSecurityPage() {
    return (
        <>
            <OtpSecurity />
        </>
    )
}
export default OtpSecurityPage
