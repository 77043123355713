import ListTickets from "../../../../components/admin/disput/listTickets/ListTickets"



function ListTicketsPage() {
    return (
        <>
            <ListTickets />
        </>
    )
}
export default ListTicketsPage