import BillPaymentReport from "../../../components/admin/billPaymentReport/BillPaymentReport"


function BillPaymentReportPage() {
    return (
        <>
            <BillPaymentReport />
        </>
    )
}
export default BillPaymentReportPage