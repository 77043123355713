import AddPaymentRequest from "../../../../components/admin/payment/addPaymentRequest/AddPaymentRequest"


function AddPaymentRequestPage() {
    return (
        <>
            <AddPaymentRequest />
        </>
    )
}
export default AddPaymentRequestPage