import PaymentRequestByMember from "../../../../components/admin/payment/paymentRequestByMember/PaymentRequestByMember"


function PaymentRequestByMemberPage() {
    return (
        <>
            <PaymentRequestByMember />
        </>
    )
}
export default PaymentRequestByMemberPage