import CmsBillReport from "../../../components/admin/cmsBillReport/CmsBillReport"

function CmsBillReportPage() {
    return (
        <>
            <CmsBillReport />
        </>
    )
}
export default CmsBillReportPage
