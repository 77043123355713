import MerchantLoginArea from "../../components/merchantLoginArea/MerchantLoginArea"


function MerchantLoginAreaPage() {
    return (
        <>
            <MerchantLoginArea />
        </>
    )
}
export default MerchantLoginAreaPage